import React,{Component} from 'react';
import Slider from "react-slick";

// Images
import bg7 from '../../images/background/bg7.jpg';
import testiPic1 from '../../images/testimonials/RukmalRanasinghe.jpg';
import testiPic2 from '../../images/testimonials/RoshanJayawardhana.jpg';
import testiPic3 from '../../images/testimonials/SajeewaSenevirathna.jpg';
import testiPic4 from '../../images/testimonials/GayaniHettiarachchi.jpg';
import testiPic5 from '../../images/testimonials/BuddhikaJayawardhana.jpg';
import testiPic6 from '../../images/testimonials/JanakaWeerasinghe.jpg';
import testiPic7 from '../../images/testimonials/LushanNalinDeSilva.jpg';
import testiPic8 from '../../images/testimonials/NandanaWijewardhana.jpg';
import testiPic9 from '../../images/testimonials/PasinduManuppriya.jpg';
import testiPic10 from '../../images/testimonials/SamajHasantha.jpg';
import testiPic11 from '../../images/testimonials/ThiliniDeZoysa.jpg';
import testiPic12 from '../../images/testimonials/wijayaranaweera.jpg';
import testiPic13 from '../../images/testimonials/VichithraSampath.jpg';
import testiPic14 from '../../images/testimonials/NimeshSathsara.jpg';
import testiPic15 from '../../images/testimonials/RoshanMuthukumara.jpg';

// Content
const content = [
	{ 
		thumb: testiPic3,
		name: "Mr. Sajeewa Senevirathne",
		work: "Maths",
		text: "BSc. (Hons) in BIS (1st Class).Has worked as an Ordinary Level / Advanced Level Mathematics Teacher for 20 years. ",
	},
	{ 
		thumb: testiPic1,
		name: "Mr. Rukmal Ranasinghe ",
		work: "Science",
		text: "BSc. (University of Kelaniya).Post Graduate Diploma in Education (University of Colombo),National Diploma in Science (Nilwala College of Education), Sri Lanka Principal Service, Science paper making teacher & exam supervisor",
	},
	{ 
		thumb: testiPic15,
		name: "Mr. Roshan Muthukumara",
		work: "Sinhala",
		text: "Bachelor of Arts (Special Hons.) University of Kelaniya, Dharmacharya (passed), Professional Compere and Announcer for all indoor and outdoor functions",
	},
	{ 
		thumb: testiPic2,
		name: "Mr. Roshan Jayawardena",
		work: "English Medium Maths",
		text: "MBA (University of Colombo), BSc. (Hons) (University of Moratuwa), MSc. (Computer Society of Sri Lanka), Certificate in Teaching in Higher Education",
	},
	{ 
		thumb: testiPic7,
		name: "Mr.Lushan Nalin De Silva",
		work: "Tamil",
		text: "Special Training National Education College (Hatton).Diploma in Tamil (merit) University of Kelaniya ,Diploma in Tamil Translations & Interpretation, University of Kelaniya,Special Speech Training Tamil Course, Department of Official Languages ",
	},
	{ 
		thumb: testiPic9,
		name: "Mr. Pasindu Hettiarachchi",
		work: "Designer",
		text: "Professional Qualification in Human Resource Management – CIPM",
	},
	{ 
		thumb: testiPic14,
		name: "Mr.Nimesh Sathsara",
		work: "Designer",
		text: "BA in Buddhist Civilization (SP), MA in Buddhist Studies (BUP), MA (Master if Buddhist Aurvedic Counselling).Higher Diploma in English, Higher Diploma in Buddhism (English medium),English Diploma.Thripitakacharya, Thripitakavisharadha, Dharmacharya.IT (NVQ iii),Visiting lecturer of SIBCC University (1 year),External lecturer of Sri Jayawardhanapura University (1 year)",
	},
	{ 
		thumb: testiPic10,
		name: "Mr.Samaj Hasantha De Silva",
		work: "History",
		text: "BA (SP) History (Hons), MPhil (U), History and Archeology Researcher,Writer (Welithota Abisen Abhimana Honorary Award Winner)",
	},
	{ 
		thumb: testiPic12,
		name: "Mr. Wijaya Ranaweera",
		work: "Designer",
		text: "BIT – University of Colombo,NDNLE – University of Vocational Technology ",
	},
	{ 
		thumb: testiPic8,
		name: "Mr. Nandana Wijewardhana",
		work: "English",
		text: "BA (University of Ruhuna),Diploma in Teaching English and Literature (Merit), International School Teacher, Government Technical College Instructor .Exam preparation coach for British Council Exams",
	},
	{
		thumb: testiPic11,
		name: "Mrs. Thilini Thushari De Soysa",
		work: "Science",
		text: "National Diploma in Teaching Science,B. Ed in Natural Science (U)",
	},
]

class Teachers extends Component{
	render(){
		
		const settings = {
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				<div className="section-area section-sp2" style={{backgroundImage:"url("+bg7+")", backgroundSize:"cover", backgroundPosition: "center"}}>
					<div className="container">
						<div className="row">
							<div className="col-md-12 text-black heading-bx left">
								<h2 className="title-head">Our Qualified <span>Teachers</span></h2>
								<p>Learn Best, From the BEST</p>
							</div>
						</div>
						<Slider {...settings} className="courses-carousel slick-slider owl-btn-1">
							{content.map((item)=>(
								<div className="slider-item">
									<div className="testimonial-bx">
										<div className="testimonial-head">
											<div className="testimonial-thumb">
												<img src={item.thumb} alt=""/>
											</div>
											<div className="testimonial-info">
												<h5 className="name">{item.name}</h5>
												<p>-{item.work}</p>
											</div>
										</div>
										<div className="testimonial-content">
											<p>{item.text}</p>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</>
		);
	}
}

export default Teachers;