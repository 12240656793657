import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Images
import aboutPic1 from '../../../images/about/about.jpg';

class OurStory1 extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return(
			<>
				<div className="section-area bg-gray section-sp1 our-story">
					<div className="container">
						<div className="row align-items-center d-flex">
							<div className="col-lg-5 col-md-12 m-b30">
								<div className="heading-bx left mb-3">
									<h2 className="title-head">Our <span>Story</span></h2>
								</div>
								<p className="m-b30">Our aim is to educate children from kindergarten to grade 13 in a formal way at reasonable prices affordable to their parents from different income levels. One of the most pressing problems, the parents are facing today is the lack of opportunity to obtain the services of well qualified and adequately experienced teachers who have ability to teach in an attractive manner to the utmost satisfaction of their children. Due to this reason students failed to get good results at the examinations irrespective of the fact that they possess different abilities and skills.To cater to this problem effectively our institution has taken steps to employ the services of well qualified well experienced teaching staff. Students who have passed out from our institutions will bear evidence to prove this fact.
								It has been already established by the students who participated in our online study programs all over the island, that modern technological instruments made used in our online programs have maintained similar effective and significant of a traditional classroom study program.</p>
								{/* <Link to="/blog-details" className="btn">Read More</Link> */}
							</div>
							<div className="col-lg-7 col-md-12 heading-bx p-lr">
								<div className="video-bx">
									<img src={aboutPic1} alt=""/>
									{/* <Link onClick={this.openModal} to="#" className="popup-youtube video"><i className="fa fa-play"></i></Link> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='x_sJzVe9P_8' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default OurStory1;