import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll';

// Layout
import Header from "../layout/header/header-new";
import Footer from "../layout/footer/footer1";

// Images
import bannerImg from '../../images/banner/banner2.jpg';
import testiPic1 from '../../images/testimonials/pic1.jpg';
import testiPic2 from '../../images/testimonials/pic2.jpg';
import blogDefaultThum1 from '../../images/blog/default/thum1.jpg';

class CoursesDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bannerImg+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Timetables of each group are as follows</h1>
							 </div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li>Time Tables</li>
							</ul>
						</div>
					</div>
					
					<div className="content-block">
						
						<div className="section-area section-sp1">
							<div className="container">
								 <div className="row d-flex flex-row">
								
									<div className="col-xl-10 col-lg-9 col-md-12 col-sm-12">
										<div className="courses-post">
											<div className="ttr-post-info m-b30">
												<div className="ttr-post-title ">
													<h2 className="post-title">Group 1</h2>
												</div>
												<div className="ttr-post-text">
													<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</div>
                                                <div className="ttr-post-media media-effect">
												<Link to="#"><img src={blogDefaultThum1} alt=""/></Link>
											    </div>
												<div className="pt-btn-join2">
													<Link to="/contact-class" className="btn" style={{margin:"10px" }}>Join Now</Link>
												</div>
											</div>
                                            <div className="ttr-post-info m-b30">
												<div className="ttr-post-title ">
													<h2 className="post-title">Group 2</h2>
												</div>
												<div className="ttr-post-text">
													<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</div>
                                                <div className="ttr-post-media media-effect">
												<Link to="#"><img src={blogDefaultThum1} alt=""/></Link>
											    </div>
												<div className="pt-btn-join2">
													<Link to="/contact-class" className="btn" style={{margin:"10px" }}>Join Now</Link>
												</div>
											</div>
										</div>
										<div className="courese-overview" id="overview">
											<h4>Overview</h4>
											<div className="row">
												<div className="col-md-12 col-lg-4">
													<ul className="course-features">
														<li><i className="ti-book"></i> <span className="label">Lectures</span> <span className="value">8</span></li>
														<li><i className="ti-help-alt"></i> <span className="label">Tests</span> <span className="value">1</span></li>
														<li><i className="ti-time"></i> <span className="label">Duration</span> <span className="value">60 hours</span></li>
														<li><i className="ti-stats-up"></i> <span className="label">Skill level</span> <span className="value">Beginner</span></li>
														<li><i className="ti-smallcap"></i> <span className="label">Language</span> <span className="value">English</span></li>
														<li><i className="ti-user"></i> <span className="label">Students</span> <span className="value">32</span></li>
													</ul>
												</div>
												<div className="col-md-12 col-lg-8">
													<h5 className="m-b10">Subjects</h5>
													<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
													<h5 className="m-b10">Learning Outcomes</h5>
													<ul className="list-checked primary">
														<li>Over 37 lectures and 55.5 hours of content!</li>
														<li>LIVE PROJECT End to End Software Testing Training Included.</li>
														<li>Learn Software Testing and Automation basics from a professional trainer from your own desk.</li>
														<li>Information packed practical training starting from basics to advanced testing techniques.</li>
														<li>Best suitable for beginners to advanced level users and who learn faster when demonstrated.</li>
														<li>Course content designed by considering current software testing technology and the job market.</li>
														<li>Practical assignments at the end of every session.</li>
														<li>Practical learning experience with live project work and examples.cv</li>
													</ul>
												</div>
											</div>
										</div>
										
										
										<div className="" id="instructor">
											<h4>Instructors</h4>
											<div className="instructor-bx">
												<div className="instructor-author">
													<img src={testiPic1} alt=""/>
												</div>
												<div className="instructor-info">
													<h6>Keny White </h6>
													<span>Professor</span>
													<ul className="list-inline m-tb10">
														<li><Link to="#" className="btn sharp-sm facebook"><i className="fa fa-facebook"></i></Link></li>
														<li><Link to="#" className="btn sharp-sm twitter"><i className="fa fa-twitter"></i></Link></li>
														<li><Link to="#" className="btn sharp-sm linkedin"><i className="fa fa-linkedin"></i></Link></li>
														<li><Link to="#" className="btn sharp-sm google-plus"><i className="fa fa-google-plus"></i></Link></li>
													</ul>
													<p className="m-b0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</div>
											</div>
											<div className="instructor-bx">
												<div className="instructor-author">
													<img src={testiPic2} alt=""/>
												</div>
												<div className="instructor-info">
													<h6>Keny White </h6>
													<span>Professor</span>
													<ul className="list-inline m-tb10">
														<li><Link to="#" className="btn sharp-sm facebook"><i className="fa fa-facebook"></i></Link></li>
														<li><Link to="#" className="btn sharp-sm twitter"><i className="fa fa-twitter"></i></Link></li>
														<li><Link to="#" className="btn sharp-sm linkedin"><i className="fa fa-linkedin"></i></Link></li>
														<li><Link to="#" className="btn sharp-sm google-plus"><i className="fa fa-google-plus"></i></Link></li>
													</ul>
													<p className="m-b0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer/>
				
			</>
		);
	}
}

export default CoursesDetails;