import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion, Card} from 'react-bootstrap';

// Layout
import Header from "../layout/header/header-new";
import Footer from "../layout/footer/footer1";

// Elements
import Counter2 from '../elements/counter/counter2';
import Testimonial2 from '../elements/testimonial1';

// Images
import bannerImg from '../../images/banner/banner1.jpg';

class Faq1 extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bannerImg+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Frequently Asked Questions</h1>
							 </div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li>Faqs</li>
							</ul>
						</div>
					</div>
					
					<div className="content-block">
						
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row">
									<div className="col-12 m-b30">
										<h3 className="m-b15">Asked <span> Questions</span></h3>
										<p className="m-b0">Containes the most asked questions from us</p>
									</div>
									<div className="col-lg-6 col-md-12">
										<Accordion className="ttr-accordion m-b30 faq-bx">
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="0">
													<Link to="#" className="acod-title">How can we be sure that this program will continue?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="0">
													<Card.Body>
													We first started this online program with the 2019 Corona wave. Even during the curfew period, all teachers came to the Institute with the assistance of the Sri Lanka Police and continued their teaching activities. The majority of parents are of the opinion that they continue to participate their children in the Wisdom online class because they believe that by attending the online classes conducted by our Institute, the education of the child can be carried out in a very systematic manner and the children will continue to participate in the Wisdom online class as the children will attend the classes willingly due to the skill of the teachers and they see a drastic development of their children than when they were attending other ordinary classes. Therefore, this blessing alone is sufficient to sustain this program
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="1">
													<Link to="#" className="acod-title">What are the class timings?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="1">
													<Card.Body>
													Most classes are after 6 p.m
													</Card.Body>
												</Accordion.Collapse>
											</Card>										
										</Accordion>
									</div>
									<div className="col-lg-6 col-md-12">
										<Accordion className="ttr-accordion m-b30 faq-bx">
										<Card>
												<Accordion.Toggle as={Card.Header} eventKey="2">
													<Link to="#" className="acod-title">Will this class fee continue this way, or will it change later?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="2">
													<Card.Body>
													No. Class fees do not change for any reason. 
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="3">
													<Link to="#" className="acod-title">How to join the Institute?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="3">
													<Card.Body>
													Please WhatsApp your Child's Name, WhatsApp Number, Grade, Course Name to 0761100732 or give us a call.
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</Accordion>
									
									</div>
									
								</div>
								
								
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer/>
				
			</>
		);
	}
}

export default Faq1;