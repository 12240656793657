import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

// Images
import pic1 from "../../../images/slider/banner1.jpg"
import pic2 from "../../../images/slider/banner2.jpg"
import pic3 from "../../../images/slider/banner3.jpg"
import pic4 from "../../../images/slider/banner4.jpg"

class Slider2 extends Component{
	
	render(){
		
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
    		autoplay: true,
    		autoplaySpeed: 2000,
		};
		
		return(
			<div>
				
				<Slider {...settings} className="tt-slider slider-two slider-sp0 owl-btn-1">
					<div className="slider-item">
						<div className="slider-thumb">
							<img src={pic1}/>
						</div>
						
						{/* <div className="slider-content">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-7">
										<div className="content-inner">
											<h6 className="sub-title">Welcome To Wisdom Education Unit - Dharmaraja College</h6>
											<h2 className="title text-primary">Education For A Better Tommorow</h2>
											<p> Join Us, Achieve the BEST</p>
											<Link className="btn radius-xl m-r15 button-md white" to="/contact">Contact Us</Link>
											<Link className="btn radius-xl button-md" to="/about-1">Read More</Link>
										</div>
									</div>
									<div className="col-lg-5">
										<div className="slider-img">
											<img src={pic2} alt=""/>
										</div>
									</div>
								</div>
							</div>
						</div> */}
					</div>			
					<div className="slider-item">
						<div className="slider-thumb">
							<img src={pic2} alt=""/>
						</div>
					</div>
					<div className="slider-item">
						<div className="slider-thumb">
							<img src={pic3} alt=""/>
						</div>
					</div>
					<div className="slider-item">
						<div className="slider-thumb">
							<img src={pic4} alt=""/>
						</div>
					</div>					
				</Slider>
				
			</div>
		);
	}
}

export default Slider2;
