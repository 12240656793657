import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServicesContent3 extends Component{
	render(){
		return(
			<>
				<div className="row">
							<div className="col-md-12 heading-bx left">
								<h2 className="title-head">Why Choose <span> Our Institution</span></h2>
								<p>Our Institute is a government approved, registered Higher Education Institute which conducts high level technical 
									online teaching under the guidance of a highly qualified teaching staff which includes government school teachers, teacher advisors, university lecturers and graduate teachers</p>
							</div>
						</div>
				<div className="row">
					<div className="col-lg-4 col-md-6">
						<div className="services-bx text-center m-b30">
							<div className="feature-lg text-white m-b30">
								<span className="icon-cell"><i className="flaticon-professor"></i></span> 
							</div>
							<div className="icon-content">
								<h4 className="ttr-tilte">Qualified Teachers</h4>
								<p>Assure the best education form the best teachers</p>
								{/* <Link to="/about-1" className="readmore">Learn More <i className="la la-arrow-right"></i></Link> */}
							</div>
							{/* <div className="service-no">01</div> */}
						</div>
					</div>
					<div className="col-lg-4 col-md-6">
						<div className="services-bx text-center m-b30">
							<div className="feature-lg text-white m-b30">
								<span className="icon-cell"><i className="flaticon-exam"></i></span> 
							</div>
							<div className="icon-content">
								<h4 className="ttr-tilte">Online Learning</h4>
								<p>Anyone can join to our online classes, from home</p>
								{/* <Link to="/about-1" className="readmore">Learn More <i className="la la-arrow-right"></i></Link> */}
							</div>
							{/* <div className="service-no">02</div> */}
						</div>
					</div>
					<div className="col-lg-4 col-md-12">
						<div className="services-bx text-center">
							<div className="feature-lg text-white m-b30">
								<span className="icon-cell"><i className="flaticon-diploma"></i></span> 
							</div>
							<div className="icon-content">
								<h4 className="ttr-tilte">Certification</h4>
								<p>Recognized certificates after successfull completions</p>
								{/* <Link to="/about-1" className="readmore">Learn More <i className="la la-arrow-right"></i></Link> */}
							</div>
							{/* <div className="service-no">03</div> */}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default ServicesContent3;