import React,{Component} from 'react'; 
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import coursesPic1 from '../../images/courses/ComputerCourses.jpg';
import coursesPic2 from '../../images/courses/LanguageClasess.jpg';
import coursesPic3 from '../../images/courses/OnlineClasess.jpg';
import coursesPic4 from '../../images/courses/PhysicalClasess.jpg';

// Content
const content = [
	{ 
		thumb: coursesPic3,
		tag: "All subjects from grade 1 to grade 11 ",
		title: "Online Classes",
		link_to: "/courses-details-school"
	},
	{ 
		thumb: coursesPic4,
		tag: "All subjects from grade 1 to grade 11 ",
		title: "Physical Classes",
		link_to: "/courses-details-languages"
	},
	{ 
		thumb: coursesPic2,
		title: "English, Tamil, Russian, Korean, Japanese, German, French, Chinese ",
		tag: "Language Classes",
		link_to: "/courses-details-languages"
	},
	{ 
		thumb: coursesPic1,
		tag: "Computer Courses ",
		title: "For any age group",
		link_to: "/courses-details-languages"
	},
	// { 
	// 	thumb: coursesPic3,
	// 	tag: "Professional Courses",
	// 	title: "Sewing, Cake ",
	// 	link_to: "/ncourses-details-primary"
	// },
]

class PopularCoursesSlider extends Component{
	render(){
		
		const settings = {
			infinite: false,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 360,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				<div className="section-area section-sp1 bg-tp popular-courses-bx">
					<div className="container">
					<div className="row">
							<div className="col-md-12 heading-bx left">
								<h2 className="title-head">Select your <span> Class</span></h2>
								<p>Click to see the course details and time tables </p>
							</div>
						</div>
						<Slider {...settings} className=" slick-slider owl-btn-1">
						{content.map((item)=>(
						<div className="slider-item">
							<div className="cours-bx">
								<div className="action-box">
									<img src={item.thumb} alt=""/>
								</div>
								<div className="info-bx">
									<h6>{item.tag}</h6>
									<span>{item.title}</span>
									<Link to={item.link_to} className="btn">Time Table</Link>
								</div>
							</div>
						</div>
					))}
						</Slider>
					</div>
				</div>
			</>
		);
	}
}

export default PopularCoursesSlider;