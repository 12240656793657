import React, {Component} from 'react';

// Layout
import Header from "../layout/header/header-new";
import Footer from "../layout/footer/footer1";

// Elements
import MainSlider from '../elements/slider/slider2';
import OurStory1 from '../elements/our-story/our-story1';
import ServicesContent3 from '../elements/services-content-3';
import AppointmentBox from '../elements/appointment-box';
import EventsContent2 from '../elements/events-content2';
import Testimonial3 from '../elements/testimonial3';
import PopularCoursesSlider from '../elements/popular-courses-slider';
import OnlineCourses from '../elements/online-courses';
import Teachers from '../elements/teachers';

// Images
import bg4 from '../../images/background/bg4.jpg';

class Index3 extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
				
					<MainSlider />
					
					<div className="content-block" id="content-area">
						
						<div className="popular-courses-bx" style={{backgroundImage:"url("+bg4+")", backgroundSize: "cover"}}>
							<div className="section-area section-sp3">
								<div className="container">
									{/* <div className="row">
										<div className="col-md-12 heading-bx style1 text-center">
											<h2 className="title-head">Why Choose Us?</h2>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page</p>
										</div>
									</div> */}
									
									<ServicesContent3 />
									
								</div>
							</div>
							
							<div className="section-area section-sp1">
								<div className="container-fluid">
									{/* <div className="row">
										<div className="col-md-12 heading-bx style1 text-center">
											<h2 className="title-head">Our Classes</h2>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page</p>
										</div>
									</div> */}
									
									<PopularCoursesSlider />
								
								</div>
							</div>
						</div>
						
						<OnlineCourses />

						{/* <AppointmentBox /> */}
						
						{/* <EventsContent2 /> */}
												
						<OurStory1 />
						
						{/* <Testimonial3 /> */}
						<Teachers />
												
					</div>
					
				</div>
				
				<Footer/>
				
			</>
		);
	}
}

export default Index3;