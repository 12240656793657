import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Iframe from 'react-iframe'

// Layout
import Header from "../layout/header/header-new";
import Footer from "../layout/footer/footer1";

// Images
import bannerImg from '../../images/banner/banner2.jpg';

// Elements
import ContactInfoCard from '../elements/contact-info-card';

class Contact2 extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bannerImg+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Contact Us</h1>
							 </div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li>Contact Us</li>
							</ul>
						</div>
					</div>
					
					<div className="content-block">
						
						<div className="page-banner contact-page">
							<div className="container-fuild">
								<div className="row m-lr0">
									<div className="col-lg-6 col-md-6 p-lr0 d-flex map-frame1">
										<Iframe src="https://maps.google.com/maps?q=Dharmaraja%20Vidyalaya%20Aluthgama%20Education%20Institute&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                                        className="align-self-stretch d-flex" width="40%" height="100%"></Iframe>
									</div>
                                    <ContactInfoCard />
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer/>
				
			</>
		);
	}
}

export default Contact2;