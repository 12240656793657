import React,{Component} from 'react'; 
import { Link } from 'react-router-dom';

class ContactInfoCard extends Component{
	render(){
		return(
			<>
				<div className="bg-primary text-white contact-info-bx" style={{marginLeft:"20px", marginBottom:"20px"}}>
					<div className="heading-bx left mb-4">
						<h3 className="m-b10 title-head">Contact <span> Info</span></h3>
						{/* <p className="m-b0">It is a long established fact that a reader will be distracted</p> */}
					</div>
					<div className="widget widget_getintuch">	
						<ul>
							<li><i className="ti-location-pin"></i>Wisdom Education Unit,Dharmaraja Vidyalaya,No. 398,Galle Road,Aluthgama.</li>
							<li><i className="ti-mobile"></i>0342270272</li>
							<li><i className="ti-email"></i>info@wisdomeducationalunit.com</li>
						</ul>
					</div>
					<h5 className="m-t0 m-b20">Follow Us</h5>
					<ul className="list-inline contact-social-bx m-b0">
						<li><a target="_blank" href="https://www.facebook.com/wisdomeducationalunit"  className="btn outline radius-xl"><i className="fa fa-facebook"></i></a></li>
					</ul>
				</div>
			</>
		);
	}
}

export default ContactInfoCard;